import { Link } from "@remix-run/react";
import type { ComponentPropsWithoutRef } from "react";
import { RiAwardLine } from "react-icons/ri";
import type { Range } from "~types/api/range.types";
import type { SessionData } from "~types/app/session.types";

import rangeBackgroundImg from "~/assets/images/fallback/range-background-fallback.jpg";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { Switch } from "~/components/ui/forms/switch";
import { Image } from "~/components/ui/image";
import { useComparator } from "~/hooks/use-comparator";
import { cn } from "~/utils/classnames";

interface RangeCardProps extends ComponentPropsWithoutRef<"div"> {
  range: Range;
  comparator: SessionData["comparator"];
  isUserConnected: boolean;
}

export const RangeCard = ({
  range,
  comparator,
  className,
  isUserConnected,
  ...props
}: RangeCardProps) => {
  const {
    isInComparator,
    handleComparatorRangeChange,
    isComparatorFetcherLoading,
    isComparatorFetcherFailed,
  } = useComparator({ comparator });

  return (
    <Card className={cn("relative h-full overflow-hidden text-white", className)} {...props}>
      <Image
        src={range.illustrationUrl || rangeBackgroundImg}
        fallbackSrc={rangeBackgroundImg}
        alt={range.brand.title}
        className="absolute inset-0 h-full w-full object-cover brightness-50"
      />

      <div className="absolute left-0 top-6 flex min-w-20 items-center justify-center overflow-hidden rounded-r-lg border border-l-0 border-grey-100 bg-white px-3 py-0 text-xs font-medium text-grey-900">
        <Image
          src={range.brand?.images[0]?.url}
          alt={range.brand?.title || ""}
          className="h-14 object-contain"
          fallbackElement={<div>{range.brand?.title}</div>}
        />
      </div>

      <div className="relative flex h-full flex-col">
        <Link
          to={`/systemes-de-fixation/gamme/${range.slug}`}
          className="mt-24 min-h-52 text-center"
        >
          <div className="text-lg">Gamme</div>
          <h2 className="mt-2 text-2xl">{range.brandRange.title}</h2>
          <p className="mt-2 text-sm">{range.subHeading}</p>
        </Link>

        {isUserConnected ? (
          <div className="flex items-center justify-between gap-3 border-y border-grey-200 py-2">
            <label htmlFor="comparator-switch" className="block text-xs">
              Comparer cette gamme
            </label>
            <Switch
              key={isComparatorFetcherFailed?.toString()}
              id={`${range.brand.title}-${range.brandRange.title}-comparator`}
              label="Comparer ce produit"
              disabled={!!isComparatorFetcherLoading}
              defaultChecked={isInComparator(range.id) || false}
              onChange={(checked) =>
                handleComparatorRangeChange({
                  id: range.id,
                  categorySlug: "systemes-de-fixation",
                  checked,
                })
              }
            />
          </div>
        ) : null}

        <div className="mt-4 flex flex-wrap gap-2">
          <Button
            as="link"
            to={`/systemes-de-fixation/gamme/${range.slug}`}
            variant="outline"
            className="grow border-grey-50 text-grey-50"
          >
            + d&apos;infos
          </Button>
          <Button
            as="link"
            to={`/systemes-de-fixation?gammes=${range.slug}`}
            className="grow bg-grey-50 text-grey-900 hover:bg-grey-100"
          >
            Voir les produits
          </Button>
        </div>
      </div>

      {range.certifications.length > 0 ? (
        <button
          className="group absolute right-8 top-0 flex h-12 w-10 items-center justify-center rounded-b border border-grey-100 bg-white"
          onClick={() => {
            range.certifications.forEach((certification) => {
              window.open(`${certification.documents[0].url}`, "_blank");
            });
          }}
        >
          <RiAwardLine className="size-5 text-grey-800" />
          <aside className="invisible absolute -right-2 top-16 z-10 w-64 rounded-lg border border-grey-700 bg-grey-900 p-4 text-sm opacity-0 group-hover:visible group-hover:opacity-100">
            <div className="RangeCard-certifiedBadge-popover-arrow" />
            <p className="">Certifications pour cette gamme: </p>
            <ul className="bulleted-list mt-4 text-white">
              {range.certifications.map((certification) => (
                <li key={certification.id} className="mt-2 text-white">
                  {certification.type} - {certification.title}
                </li>
              ))}
            </ul>
          </aside>
        </button>
      ) : null}
    </Card>
  );
};
